import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, Cookie } from '@vezeeta/web-utils';
import { Urls } from 'configs/Urls';

import {
  PHARMA,
  getPharmaProductsFail,
  getPharmaProductsSuccess,
  getPrescriptionSettingsSuccess,
  getPrescriptionSettingsFail,
  submitEprescriptionFail,
  submitEprescriptionSuccess,
  getEPrescriptionFail,
  getEPrescriptionSuccess,
  getPrescriptionFail,
  getPrescriptionSuccess,
  getPreviouslyAddedMedicationsSuccess,
  getPreviouslyAddedMedicationsFail,
  getLabTestsProductsSuccess,
  getLabTestsProductsFail,
  getScansCatalogSuccess,
  getScansCatalogFail,
} from '../actions/Pharma';
import { language } from '../types/language';

function* fetchPrescriptionSettings(action) {
  const prescriptionSettingsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield prescriptionSettingsAPI.get(
    `${Urls.getPrescriptionSettings}?productKey=${action.productKey}&language=${culture}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getPrescriptionSettingsSuccess({
        ...response.data,
        ...action.payload,
        productKey: action.productKey,
      }),
    );
  } else {
    yield put(
      getPrescriptionSettingsFail({
        ...action.payload,
        productKey: action.productKey,
      }),
    );
  }
}

function* fetchPharmaProducts(action) {
  const pharmaProductsAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield pharmaProductsAPI.get(
    `${Urls.pharmaProductShape}?Query=${action.keyWord}&From=1&Size=100`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getPharmaProductsSuccess({
        ...response.response,
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  } else {
    yield put(
      getPharmaProductsFail({
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  }
}


function mapServiceLabsToLabs(service) {
  return {
    ServiceKey: service.serviceKey,
    ServiceTranslators: [
          {
              Culture: language, 
              Name: language==='en'?service.serviceNameEn : service.serviceNameAr
          }
      ],
     
  };
}

function mapServicesResponseToLabsResponse(servicesResponse) {
  const mappedLabs = servicesResponse?.data?.map(service => mapServiceLabsToLabs(service));
  return {
    Data:{
      Services: mappedLabs,
      TotalCount: mappedLabs?.length
    },
     
  };
}


function* fetchLabTestsProducts(action) {
  const labTestsProductAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    }
  ];

  const response = yield labTestsProductAPI.get(
    `${Urls.searchCategory}?query=${action.keyWord}&categoryKey=labs&pagenum=1`,
    header,
  );
  const mappedResponse = mapServicesResponseToLabsResponse(response.response);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getLabTestsProductsSuccess({
        ...mappedResponse,
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  } else {
    yield put(
      getLabTestsProductsFail({
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  }
}

function* fetchPrevAddedMedications() {
  const api = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield api.get(`${Urls.getPreivouslyAddedItems}`, header);

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getPreviouslyAddedMedicationsSuccess({
        ...response.data,
      }),
    );
  } else {
    yield put(getPreviouslyAddedMedicationsFail());
  }
}

function* submitEprescription(action) {
  const submitEprescriptionAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  const culture = Cookie.get(Cookie.CULTURE);
  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield submitEprescriptionAPI.post(
    Urls.upsertEPrescription,
    action.payload,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      submitEprescriptionSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      submitEprescriptionFail({
        ...action.payload,
      }),
    );
  }
}

function* fetchEPresc(action) {
  const getEPrescAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);
  let culture = Cookie.get(Cookie.CULTURE);
  if (culture.split('-')[0] === 'en') {
    culture = 'en-US';
  }

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    },
    {
      key: 'Language',
      value: `${culture}`,
    },
  ];

  const response = yield getEPrescAPI.get(
    `${Urls.getEPrescription}?operationKey=${action.payload.reservationKey}`,
    header,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getEPrescriptionSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      getEPrescriptionFail({
        ...action.payload,
      }),
    );
  }
}

function* fetchPresc(action) {
  const accountToken = Cookie.get(Cookie.AUTH_TOKEN);
  const Headers = [
    {
      key: 'x-vzt-authentication',
      value: accountToken,
    },
  ];
  const getPrescAPI = new API();

  const response = yield getPrescAPI.get(
    `${Urls.prescription.getPrescription}${action.payload.reservationKey}`,
    Headers,
  );

  if (STATUS.isSuccess(response.status)) {
    yield put(
      getPrescriptionSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      getPrescriptionFail({
        ...action.payload,
      }),
    );
  }
}

function mapServiceScansToScans(service) {
  return {
      ScanKey: service.serviceKey,
      ScanTranslators: [
          {
              Culture: language, // Assuming English culture for the translator
              Name: language==='en'?service.serviceNameEn : service.serviceNameAr
          }
      ],
     
  };
}

function mapServicesResponseToScansResponse(servicesResponse) {
  const mappedscans = servicesResponse?.data?.map(service =>
     mapServiceScansToScans(service));
  return {
    Data:{
      Scans: mappedscans,
      TotalCount: mappedscans?.length
    },
     
  };
}



function* fetchScansCatalog(action) {
  const labTestsProductAPI = new API();
  const authToken = Cookie.get(Cookie.AUTH_TOKEN);

  const header = [
    {
      key: 'x-vzt-authentication',
      value: `${authToken}`,
    }
  ];

  const response = yield labTestsProductAPI.get(`${Urls.searchCategory}?query=${action.keyWord}&categoryKey=scans&pagenum=1`, header);
  const mappedResponse = mapServicesResponseToScansResponse(response.response);
  if (STATUS.isSuccess(response.status)) {
    yield put(
      getScansCatalogSuccess({
        ...mappedResponse,
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  } else {
    yield put(
      getScansCatalogFail({
        ...action.payload,
        keyWord: action.keyWord,
      }),
    );
  }
}

function* activitiesSaga() {
  yield takeLatest(PHARMA.PHARMA_PRODUCTS.LOAD, fetchPharmaProducts);
  yield takeLatest(PHARMA.LAB_TESTS_PRODUCTS.LOAD, fetchLabTestsProducts);
  yield takeLatest(PHARMA.GET_PRESCRIPTION_SETTINGS.LOAD, fetchPrescriptionSettings);
  yield takeLatest(PHARMA.SUBMIT_EPRESCRIPTION.SUBMIT, submitEprescription);
  yield takeLatest(PHARMA.GET_EPRESC.LOAD, fetchEPresc);
  yield takeLatest(PHARMA.GET_PRESC.LOAD, fetchPresc);
  yield takeLatest(PHARMA.GET_PREVIOUSLY_ADDED_MEDICATIONS.LOAD, fetchPrevAddedMedications);
  yield takeLatest(PHARMA.SCANS_CATALOG.LOAD, fetchScansCatalog);
}

export default activitiesSaga;
